import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core/styles";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import StarIcon from '@material-ui/icons/Star';
import ReactCardFlip from 'react-card-flip';
import QRCode from "react-qr-code";




import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import HotelIcon from '@material-ui/icons/Hotel';
import RepeatIcon from '@material-ui/icons/Repeat';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';




import Navigation from "../component/Navigation";
import PromotionList from "../component/ListItemContent";

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import FadeIn from "react-fade-in";
import Lottie from "react-lottie";
import LoadingData from "../Loading.json";
import SuccessData from "../Success.json";

import RedeemOutlinedIcon from '@material-ui/icons/RedeemOutlined';


const styles = theme => ({
  paper: {
    padding: '6px 16px',
  },
  cardTitle:{
    fontFamily: 'DBHelvethaicaX-55Regular',
    fontSize:'24px',
  },
  getPoint:{
    color:'#ffffff',
    backgroundColor: '#357a38',
  },
  usePoint:{
    color:'#ffffff',
    backgroundColor:'#aa2e25'
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
    fontFamily: 'DBHelvethaicaX-55Regular',
  },
  main:{
    backgroundColor: '#ffffff',
    fontFamily: 'DBHelvethaicaX-55Regular',
    height:'100vh'
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  PageContent: {
    backgroundColor: '#000000',
    height:'100vh'
  },
  PageContentloading:{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  LogImage :{
    width:'100%'
  },
  button :{
    maxWidth: '560px',
    minHeight: '50px',
    textAlign: 'center',
    lineHeight: '50px',
    fontSize:'25px',
    borderRadius:'100px',
    margin:'50px auto',
    backgroundColor: '#ffffff',
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontFamily: 'DBHelvethaicaX-55Regular',
    fontSize:'20px'
  },
  point: {
    flexGrow: 1,
    fontFamily: 'DBHelvethaicaX-55Regular',
    fontSize:'20px',
    textAlign:'right'
  },
  boxCard:{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  card:{
    maxWidth:'360px',
    minHeight:'200px',
    borderRadius:'10px',
    border:"solid 1px #cccccc",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: '#2d3436',
    backgroundImage: 'linear-gradient(315deg, #2d3436 0%, #d3d3d3 74%)'

  },
  app:{
    backgroundColor:'#28292d',
    position: 'fixed',
    font:{
      fontSize:'20px',
    }
  },
  textlogo:{
    color:'#ffffff'
  },
  imageCarousel:{
    width:'100%'
  },
  tabpromotion:{
    color: '#ffffff',
    margin: '15px 0px auto',
    fontSize: '20px',
    backgroundColor: '#28292d',
    padding: '5px',
    borderRadius: '10px 10px 0 0',
  },
    titlePromotionicon:{
      display: 'inline-block',
    },
    titlePromotiontext:{
      display: 'inline-block',
      marginLeft:'5px'
    },
    tabpromotionL:{
      width:'50%',
      display: 'inline-block',
      verticalAlign: 'middle'
    },
    tabpromotionR:{
      width:'50%',
      textAlign:'right',
      display: 'inline-block',
      verticalAlign: 'middle'
    }
});



const LoadingOptions = {
  loop: true,
  autoplay: true,
  animationData: LoadingData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};
const SuccessOptions = {
  loop: false,
  autoplay: true,
  animationData: SuccessData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};


class History extends React.Component {
  state = {
    loading:false,
    isFlipped:true
  };



  RegisterNow = () =>{
    this.setState({loading:true})
    setTimeout(() => {
      this.setState({loading:true});
      this.props.history.push("/profile");
    }, 2000);
  }

  handleClick = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
  }

  render() {
    const { classes } = this.props;
    return (
      <>
      {
        this.state.loading ? (
          <div id="fadein" className={classes.PageContent}>
            <div className={classes.PageContentloading}>
              <Lottie options={LoadingOptions} height={200} width={300}/>
            </div>
          </div>
        ):(
          <div id="fadein" className={classes.PageContent}>
            <div className={classes.root}>
              <AppBar className={classes.app} position="static">
                <Toolbar>
                  <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                    {/* <StarIcon /> */}
                  </IconButton>
                  <Typography variant="h6" className={classes.title}>
                    {/* OSAKA789 */}
                  </Typography>
                  <Typography className={classes.point} color="inherit">ออกจากระบบ</Typography>
                </Toolbar>
              </AppBar>
            </div>
            
          <main className={classes.main}>
            {/* <br/>
            <br/>
            <br/>
            <Timeline align="alternate">
              <TimelineItem>
                <TimelineOppositeContent>
                  <Typography variant="body2" color="textSecondary">
                    9:30 am
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot className={classes.getPoint}>
                    <AddIcon />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography className={classes.cardTitle} variant="h6" component="h1">
                      รับคะแนน
                    </Typography>
                    <Typography>+30 คะแนน</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent>
                  <Typography variant="body2" color="textSecondary">
                    10:00 am
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot className={classes.usePoint}>
                    <RemoveIcon />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h6" component="h1">
                      Code
                    </Typography>
                    <Typography>Because it&apos;s awesome!</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>

            </Timeline>
            <br/>
            <br/>
            <br/> */}
            <Navigation/>
          </main>
          </div>
        )
      }
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(History);