import React from 'react';
import { Container } from "./styles";
import Accordion from 'react-bootstrap/Accordion'
import { Row,Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Carousel from '../../component/Carousel'
import CatalogView from '../../component/CatalogView'
import { config } from "../../configs/constant";
import axios from 'axios';




class MemberPage extends React.Component {
  state = {
    loading:"",
    content:"",
    title:"",
    author:"",
    imageUrl:"",
    review:"",
    totalEpisode:0,
    episodeNow:0,

  };

  componentDidMount(){

    var url = new URL(window.location.href);
    var id = url.searchParams.get("id");

    axios.get(config.url+'/neeyai/content/GetEpisode?id='+id)
    .then(resp=>{
      if(resp.data.status == true){

        this.setState({
          title:resp.data.docResult.title,
          author:resp.data.docResult.author,
          imageUrl:resp.data.docResult.imageUrl,
          review:resp.data.docResult.review,
          totalEpisode:resp.data.docResult.totalEpisode,
          episodeNow:resp.data.docResult.episodeNow,
        })

        this.setState({content:resp.data.docResult.listEpisode.map(o=>{
          return(
            <Accordion.Item eventKey={o.seq}>
            <Accordion.Header>{o.title}</Accordion.Header>
            <Accordion.Body>
            <div dangerouslySetInnerHTML={{ __html: o.content}} />
            </Accordion.Body>
          </Accordion.Item>
          )
        })})
      }
    })
    .catch(error=>{
    console.log(error)
    })

  }

  render() {
    return (
        <>
        <br/>
        <br/>
        <br/>
          <Container>
            <div>
              <div className='header-story'>
                <div className='box-image'>
                  <img src={this.state.imageUrl}/>
                </div>
                <div className='box-detail'>
                  <h3>{this.state.title}</h3>
                  <h4>ตอน {this.state.episodeNow}/{this.state.totalEpisode} ประพันธ์โดย : {this.state.author}</h4>
                  <p>{this.state.review}</p>
                  <hr/>
                </div>
              </div>
            </div>
          </Container>
          <Accordion defaultActiveKey="0" flush className='fix-size-content'>
             {this.state.content}
          </Accordion>
        </>
    );
  }
}

export default (MemberPage);