import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { ProgressBar, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import zIndex from '@material-ui/core/styles/zIndex';

import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import { config } from "../configs/constant";
import LiveIcon from '../asset/images/live.gif';

const MySwal = withReactContent(Swal)




const styles = theme => ({
  content:{
    maxWidth:'100%',
    backgroundColor: '#ffffff',
    paddingRight:'5px',
    margin:' 10px auto',
    // borderRadius: '5px',
  },
  boxContent:{
    display: 'flex',
    position: 'relative',
  },
  boxImage:{
    width:'100%'
  },
  imageCarousel:{
    width:'100%',
    // borderRadius:'5px'
  },
  boxdetail:{
    width:'70%',
    paddingLeft:'10px',
    paddingTop:'5px'
  },
  btnRedeem:{
    bottom: '0',
    right: '10px',
    width: '100%',
    backgroundColor: '#343a40',
    textAlign: 'center',
    fontSize: '20px',
    borderRadius: '100px',
    color: '#ffffff',
    backgroundColor: '#2876f9',
    backgroundImage: JSON.parse(localStorage.getItem("_profileSetting")).backgroundImage,
  },
  textdetail:{
    fontSize:'18px'
  },
  tabType:{
    justifyContent:'space-evenly',
    position: '-webkit-sticky',
    position: 'sticky',
    top: '56px',
    zIndex:'1'
  },
  liveicon:{
    width: '20px',
    position: 'absolute',
    top: '20%',
    right: '0',
    transform: 'translate(0, -50%)',
  }
});

const List = []

class ListLiveGame extends React.Component {
  state = {
    ListItem:'',
    progress:50,
    indexgame:4,
    category:[]
  };



  componentDidMount(){
    this.GetGameList()
  }

  LoginGame = (e) =>{
    const provider = e.target.getAttribute('provider')
    window.location.href = `https://g2gbet.com/live-casino/game/?provider=${provider}`;
  }

  GetGameList (){
    const { classes } = this.props;
    this.setState({
      ListItem:config.liveGame.map(o=> {
        return(
          <div key={o.name} className={classes.content}>
          <div className={classes.boxContent}>
            <div className={classes.boxImage}>
            <img className={classes.imageCarousel} vendor="ambgame2" src={o.image}/>
            <img className={classes.liveicon} src={LiveIcon}/>
            </div>
            <div className={classes.boxdetail}>
              <h4>{o.name}</h4>
              <div  provider={o.value}  onClick={this.LoginGame} className={classes.btnRedeem}>เล่นเกมส์</div>
            </div>
          </div>
        </div>
        )
      })
    })
  }

  Getgame = (e) =>{
    this.setState({
      indexgame:e.value
    })
    this.GetGameList()
  }

  RedeemAlert = (e) =>{
    const { classes } = this.props;
    var getItem = List.filter(o=> o.itemId == e.target.id)
  
    MySwal.fire({
      html:(
      <div className={classes.content}>
        <div className={classes.boxContent}>
          <div className={classes.boxImage}>
          <img className={classes.imageCarousel} src={getItem[0].imageUrl} />
          </div>
          <div className={classes.boxdetail}>
            <h4>{getItem[0].title}</h4>
            <h5 className={classes.textdetail}>{getItem[0].detail}</h5>
          </div>
        </div>
      </div>
      ),
      showConfirmButton: false,
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.state.ListItem}
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(ListLiveGame);