import React from 'react';
import { Route , withRouter} from 'react-router-dom';
import { Container } from "./styles";
import OtpInput from 'react-otp-input';
import Logo from '../../../asset/images/OSAKA789.png';
import { withStyles } from "@material-ui/core/styles";
import { config } from "../../../configs/constant";

import axios from "axios";


import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
const MySwal = withReactContent(Swal)



const styles = theme => ({
    btnSubmit:{
      margin:' 15px auto',
      width: '60%',
      backgroundColor: '#2876f9',
      backgroundImage: JSON.parse(localStorage.getItem("_profileSetting")).backgroundImage,
      textAlign: 'center',
      fontSize: '30px',
      borderRadius: '100px',
      color: '#ffffff'
    },
    btnSubmit2:{
      margin:' 15px 5px',
      width: '40%',
      backgroundColor: '#2876f9',
      backgroundImage: JSON.parse(localStorage.getItem("_profileSetting")).backgroundImage,
      textAlign: 'center',
      fontSize: '30px',
      borderRadius: '100px',
      color: '#ffffff',
      border: '2px solid',
      display: 'inline-block'
    },
    btnSubmit3:{
      margin:' 15px 5px',
      width: '40%',
      backgroundColor: '#2876f9',
      backgroundImage: JSON.parse(localStorage.getItem("_profileSetting")).backgroundImage,
      textAlign: 'center',
      fontSize: '30px',
      borderRadius: '100px',
      color: '#ffffff',
      border: '2px solid',
      display: 'inline-block'
    },
    content:{
      backgroundColor: '#2876f9',
      backgroundImage: JSON.parse(localStorage.getItem("_profileSetting")).backgroundImage,
      borderRadius:'5px',
      color:'#ffffff',
      padding: '10px',
    },
    appLogo:{
      width:'100%',
      position: 'absolute',
      top: '0',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    },
  });


class LuckyNumber extends React.Component {
  state = {
    userProfile:"",
    value:"",
    profileSetting:"",
    textError:"",
    AppId:0,
  };

  componentDidMount(){

    this.setState({profileSetting:JSON.parse(localStorage.getItem("_profileSetting"))})
    var url = new URL(window.location.href);
    var c = url.searchParams.get("app");
    this.setState({AppId:url.searchParams.get("app")})
  }

  handleChange = (value) => this.setState({ value });

  closePopup = (e) =>{
    MySwal.close()
  }

  AppCF = (e) =>{
    const { classes } = this.props;
    this.setState({textError:""})
    if(this.state.value.length != 3){
this.setState({textError:"กรุณาใส่ข้อมูลได้ถูกต้อง"})
      return
    }

    MySwal.fire({
      html:(
      <div className={classes.content}>
        <div className={classes.boxContent2}>
          สำหรับกิจกรรมในครั้งนี้ ท่านสามารถส่งตัวเลขได้เพียงครั้งเดียวเท่านั้น และ จะไม่สามารถเปลี่ยนแปลงตัวเลขได้ ไม่ว่าจะกรณีใดๆ
          ดังนั้นผู้ร่วมกิจกรรมโปรดตรวจสอบความถูกต้องก่อนกดยืนยัน
        </div>
        <div>
        <div className={classes.btnSubmit2} onClick={this.pushValueApp}>ยืนยัน</div>
        <div className={classes.btnSubmit3} onClick={this.closePopup}>ยกเลิก</div>
        </div>

      </div>
      ),
      showConfirmButton: false,
      backdrop:false
    })
  }




  pushValueApp = (e) => {
    document.body.classList.add("load-screen");
    const { classes } = this.props;
    axios({
        method: 'post',
        url: (`${config.url}/content/PushValueApp`),
        headers: {'Authorization': 'Bearer ' + window.localStorage.getItem("_token")},
        data:{
          AppId:this.state.AppId,
          Value:this.state.value.trim()
        }
    })
    .then(resp => {
      if(resp.data.status == true){
        window.location.replace("/Event")
      }
    })
    .catch((error) => {

    });
  }


  render() {
    const { classes } = this.props;
    return (
        <Container>
            <div className="app-conten">
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
                <img src={this.state.profileSetting.appLogo1}/>
                  <OtpInput
                        value={this.state.value}
                        onChange={this.handleChange}
                        numInputs={3}
                        separator={<span>-</span>}
                        className="box-app"
                    />
                    <div className={classes.btnSubmit} onClick={this.AppCF}>ยืนยัน</div>
                    <center><h4>{this.state.textError}</h4></center>
                    <hr/>
                    <h4>กติกาเกมส์เลขนำโชค</h4>
                    <li>ผู้เล่นจะต้องเป็นสมาชิกของ</li>
                    <li>ผู้เล่นมีสิทธิ์ทายตัวเลข 3 ท้าย โดยอ้างอิงจาก เลขรางวัลที่ 1 จากรัฐบาลไทย</li>
                    <li>ผู้เล่นกดยืนยันทายตัวเลข สำเร็จ จะไม่สามารถเปลี่ยนแปลงตัวเลขได้</li>
                    <li>ผู้เล่นสามารถทายตัวเลข ได้เพียงท่านละ 1 สิทธิ์เท่านั้น</li>
                    <li>กิจกรรมจัดขึ้น วันที่ 1 และวันที่ 16 ของทุกเดือน (อ้างอิงตามกองสลาก)</li>
                    <li>ผู้เล่นสามารถติดตามผลรางวัลได้ที่หน้ากิจกรรมของท่าน ในเวลา 17:00 น.</li>
                    <li>หากตรวจสอบพบความผิดปกติ ผู้จัดกิจกรรมสามารถยกเลิกสิทธิ์ได้ทันที</li>
                    <li>หากเกิดข้อผิดพลาดจากผู้ใช้งาน ผู้จัดกิจกรรมจะไม่รับผิดชอบทุกกรณี</li>
                    <li>หากมีการเปลี่ยนแปลงผู้จัดกิจกรรมสามารถเปลี่ยนแปลงได้ทันที โดยไม่ต้องแจ้งให้ทราบล่วงหน้า</li>
                    <hr/>
                    <div>
                    หมายเหตุ กิจกรรมดังกล่าว จัดทำขึ้นมาโดยจะเป็นกิจกรรมแลกของรางวัล และผู้จัดกิจกรรมจะไม่เรียกเก็บค่าบริการสำหรับกิจกรรมนี้
                    </div>
            </div>
        </Container>
    );
  }
}

export default withStyles(styles, { withTheme: true })(LuckyNumber);