import React from 'react';
import './App.css';
import './asset/css/fonts.css';
import './asset/css/landingpage.css';
import './asset/css/storepage.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Switch } from "react-router-dom";

import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';

// --------------- Import Page ---------------
import Landingpage from './views/LandingPage/index';
import RegisterPage from './views/RegisterPage/index';
import MemberPage from './views/MemberPage/index';
import ReadPage from './views/ReadPage/index';
import WallpaperPage from './views/WallpaperPage/index';
import ContentPage from './views/ContentPage';
import LoginPage from './views/LoginPage';
import ComingPage from './views/ComingPage/index';


import StorePage from './views/StorePage';
import Profile from './views/Profile';
import Promotion from './views/Promotion';
import Privilege from './views/Privilege';
import History from './views/History';
import Event from './views/Event';
import EventApp from './views/EventApp';
import LiveGame from './views/LiveGame';

import HeadApp from './component/HeadApp';
import Navigation from './component/Navigation';


function App() {
  
  return (
    <div>
      <BrowserRouter>
      <HeadApp/>
        <Switch>
          <Route path="/myregister" exact component={Landingpage} />
          <Route path="/" exact component={MemberPage} />
          <Route path="/register" component={RegisterPage} />
          <Route path="/member" component={MemberPage}/>
          <Route path="/read" component={ReadPage}/>
          <Route path="/wallpaper" component={WallpaperPage}/>
          <Route path="/content" component={ContentPage}/>
          <Route path="/LoginPage" component={LoginPage}/>
          
          <Route path="/storepage" component={StorePage}/>
          <Route path="/profile" component={Profile}/>
          <Route path="/promotion" component={Promotion}/>
          <Route path="/Privilege" component={Privilege}/>
          <Route path="/history" component={History}/>
          <Route path="/Event" component={Event}/>
          <Route path="/EventApp" component={EventApp}/>
          <Route path="/LiveGame" component={LiveGame}/>
          
          <Route path="/ComingPage" component={ComingPage}/>
        </Switch>
        {/* <Navigation/> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
