import React from 'react';
import { Container } from "./styles";
import { Row,Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Carousel from '../../component/Carousel'
import CatalogView from '../../component/CatalogView'




class ContentPage extends React.Component {
  state = {
    loading:"",
    nameCategory:"",
    categoryId:"",
  };

  componentDidMount(){
    var url_string = window.location.href
    var url = new URL(url_string);
    this.setState({
      nameCategory:url.searchParams.get("name"),
      categoryId:url.searchParams.get("categoryid")
    })
  }

  render() {
    return (
        <>
        <Container>
            <div>
                <Carousel/>
                <CatalogView title={this.state.nameCategory} status={false} max={0} categoryId={this.state.categoryId}/>
            </div>
            <br/>
            <br/>
        </Container>
        </>
    );
  }
}

export default (ContentPage);