import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import TextField from '@material-ui/core/TextField';

import Logo from "../asset/images/OSAKA789.png";

import FadeIn from "react-fade-in";
import Lottie from "react-lottie";
import LoadingData from "../Loading.json";
import SuccessData from "../Success.json";

import axios from "axios";
import { config } from "../configs/constant";

const AppSetting = JSON.parse(localStorage.getItem("_profileSetting"));

const styles = theme => ({
  inputbox: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
      backgroundColor: '#ffffff',
    },
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  disabel:{
    maxWidth: '560px',
    minHeight: '50px',
    textAlign: 'center',
    lineHeight: '50px',
    fontSize:'25px',
    borderRadius:'100px',
    margin:'50px auto',
    backgroundColor: '#ffffff',
    backgroundColor: '#a1a1a1',
  },
  PageContent: {
    backgroundColor: '#000000',
    height:'100vh'
  },
  PageContentloading:{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%'
  },
  LogImage :{
    width:'100%'
  },
  button :{
    maxWidth: '560px',
    minHeight: '50px',
    textAlign: 'center',
    lineHeight: '50px',
    fontSize:'25px',
    borderRadius:'100px',
    margin:'50px auto',
    backgroundColor: '#ffffff',
    backgroundColor: '#2876f9',
    backgroundImage: JSON.parse(localStorage.getItem("_profileSetting")).backgroundImage,
    color:'#ffffff'
  },
  texterror:{
    color:'#fbb800'
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
});

const LoadingOptions = {
  loop: true,
  autoplay: true,
  animationData: LoadingData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};
const SuccessOptions = {
  loop: false,
  autoplay: true,
  animationData: SuccessData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};


class StorePage extends React.Component {
  state = {
    loading:false,
    mobileNo :"",
    pin:"",
    class: false,
    profileSetting:"",
    errorText:"สมัครสมาชิก",
  };


  componentDidMount(){
    this.setState({profileSetting:JSON.parse(localStorage.getItem("_profileSetting"))})
  }

  RegisterNow = () =>{
    const regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if(regex.test(this.state.mobileNo) && this.state.pin.length == 4){
      this.setState({loading:true})
      axios.post(`${config.url}/Authenticator/LoginUser`,
      {
        StoreKey:window.localStorage.getItem("_storeKey"),
        MobileNo:this.state.mobileNo,
        Pin:this.state.pin

      })
      .then(resp => {
        if(resp.data.status){
          window.localStorage.setItem("_userProfile",JSON.stringify(resp.data.userProfile))
          window.localStorage.setItem("_token",resp.data.userProfile.token)
          this.props.history.push("/profile");
        }
        else{
          if(resp.data.userProfile == undefined || resp.data.userProfile == ""){
            this.setState({loading:false,errorText:"เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง หรือ สมัครสมาชิก"})
          }
          else{
            window.localStorage.setItem("_userProfile",JSON.stringify(resp.data.userProfile))
            window.localStorage.setItem("_token",resp.data.userProfile.token)
            this.props.history.push("/profile");
          }
        }
      })
      .catch((error) => {

      });
    }
  }

  InputLogin = (e) =>{

    this.setState({
      [e.target.name]:e.target.value
    })

    const regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if(regex.test(this.state.mobileNo) && this.state.pin.length == 3){
      this.setState({class:true})
    }
    else{
      this.setState({class:false})
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <>
      {
        this.state.loading ? (
          <div id="fadein" className={classes.PageContent}>
            <div className={classes.PageContentloading}>
              <Lottie options={LoadingOptions} height={200} width={300}/>
            </div>
          </div>
        ):(
          <div id="fadein" className={classes.PageContent}>
          <main className="fix-m-landing w-100">
              <div>
                <img className={classes.LogImage} src={this.state.profileSetting.logo}/>
              </div>
              <div className="input-form">
                <div className="title-input">เบอร์โทรศัพท์มือถือ</div>
                <input type="tel" name="mobileNo" value={this.state.mobileNo}  onChange={this.InputLogin}/>
              </div>
              <div className="input-form">
                <div className="title-input">รหัสผ่าน 4 หลัก</div>
                <input type="tel" name="pin" value={this.state.pin} onChange={this.InputLogin}/>
              </div>
              <div className={this.state.class == true ? classes.button:classes.disabel} onClick={this.RegisterNow}>
                เข้าสู่ระบบ
                <ArrowForwardIosIcon/>
              </div>
              <a href={`${JSON.parse(localStorage.getItem("_profileSetting")).regisRef}`}><center className={classes.texterror}>{this.state.errorText}</center></a>
              <br/>
          </main>
          </div>
        )
      }
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(StorePage);