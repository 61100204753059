import React from 'react';
import PropTypes from 'prop-types';
import ReactStars from "react-rating-stars-component";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye,faHeart,faComment,faListAlt } from '@fortawesome/free-solid-svg-icons'


const styles = {
    starsOuter: {
        overflow: 'hidden'
    },
    star: {
        padding: '1px'
    }
};




const StarRating = (props) => {
    const firstExample = {
        size: 30,
        value: props.rating,
        edit: false,
        view: props.view,
        epcount: props.epcount
      };

    const element = <FontAwesomeIcon icon={faEye}/>

    return (
        <div>
            {/* <div className='info-rating'>
                <div className='rating-class'>
                    <ReactStars {...firstExample} />
                </div>
                <div className='info-class'>
                    {element} {firstExample.view} 
                </div>
            </div> */}
            {
                firstExample.view != undefined
                ?
                (<div className='info-like'>
                    <div className='rating-class-like'>
                        <FontAwesomeIcon icon={faListAlt} className="icon-red"/> {Math.ceil(firstExample.epcount)}
                    </div>
                    <div className='rating-class-like'>
                        <FontAwesomeIcon icon={faHeart} className="icon-red"/> {Math.ceil(firstExample.view/2)}
                    </div>
                    <div className='info-class-like'>
                    <FontAwesomeIcon icon={faEye} className="icon-red"/> {Math.ceil(firstExample.view/3)}
                    </div>
                </div>)
                :
                ""
            }

        </div>
    );
};


export default StarRating;