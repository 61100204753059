import React from 'react'
import styled from 'styled-components'

const SubShareCSS = styled.ul.attrs({
  id: 'sub-share',
  className: 'x-margin x-padding flex flex--row flex--center height-percent'
})`
   .share-custom {
       display: block;
       text-align: center;
       padding: 1rem 1.1rem 0.8rem 1rem;
       transition: all 0.6s ease;
       color: white;
       font-size: 2rem;
       margin-right: 0.01rem;
   }
`

export default SubShareCSS
