import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import './styles/app.scss';

export default class CatalogView extends Component {
    render() {
        return (
            <div className='fix-size-content'>
                <App title={this.props.title} status={this.props.status} max={this.props.max} categoryId={this.props.categoryId}/>
            </div>
        );
    }
}