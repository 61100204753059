import React, { Component } from "react";
import { Passers } from "prop-passer";
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookMessengerShareButton,
  WhatsappShareButton,
  LineShareButton,
  VKShareButton,

  FacebookIcon,
  TwitterIcon,
  FacebookMessengerIcon,
  WhatsappIcon,
  LineIcon,
  VKIcon,
} from "react-share";

import SubShareCSS from "./CSS";

class SubShare extends Component {

  render() {

    const {
      url = this.props.url,
      title = "Steadylearner Website",
      shareImage = "https://www.steadylearner.com/static/images/brand/prop-passer.png",
      size = "2.5rem",
    } = this.props;

    const ShareList = Passers({
      url,
      className: "network__share-button",
    })({
      className: "network cursor-pointer hover transition--default",
      title: this.props.title,
    })("li");

    return (
      <SubShareCSS >
        <ShareList>
          <FacebookShareButton
            quote={title}
          >
            <FacebookIcon
              size={size}
              round={true}
            />
          </FacebookShareButton>

          <FacebookMessengerShareButton
            quote={title}
          >
            <FacebookMessengerIcon
              size={size}
              round={true}
            />
          </FacebookMessengerShareButton>

          <TwitterShareButton
            title={title}
          >
            <TwitterIcon
              size={size}
              round={true}
            />
          </TwitterShareButton>

          <WhatsappShareButton
            title={title}
            separator=":: "
          >
            <WhatsappIcon
              size={size}
              round={true}
             />
          </WhatsappShareButton>

          <LineShareButton
            title={title}
            windowWidth={750}
            windowHeight={600}
          >
            <LineIcon
              size={size}
              round={true}

            />
          </LineShareButton>

          <VKShareButton
            title={title}
            windowWidth={750}
            windowHeight={600}
          >
            <VKIcon
              size={size}
              round={true}

            />
          </VKShareButton>
        </ShareList>
      </SubShareCSS>
    );
  }
}

export default SubShare;

