import React from 'react';
import { Container } from "./styles";
import { Row,Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Carousel from '../../component/Carousel'
import CatalogView from '../../component/CatalogView'

import ImageCover from '../../asset/images/neeyai/cover1.jpg';




class WallpaperPage extends React.Component {
  state = {
    loading:"",
  };

  componentDidMount(){
  }

  render() {
    return (
        <>
        <Container>
            <div>
                <Carousel cover={ImageCover}/>
                <CatalogView status={true}/>
            </div>
        </Container>
        </>
    );
  }
}

export default (WallpaperPage);