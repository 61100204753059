import React from 'react';
import PropTypes from 'prop-types';
import MovieCard from './MovieCard';

const getMovies = (movies,status) => {
    if(movies.length > 0){
        return (
            <div className="card-deck">
                {
                    
                    movies.map(movie => <MovieCard key={movie.constantId} movie={movie} status={status}/> )
                }
                    <div key="m1" className="movie-card"></div>
                    <div key="m2" className="movie-card"></div>
                    <div key="m3" className="movie-card"></div>
                    <div key="m4" className="movie-card"></div>
                    <div key="m5" className="movie-card"></div>
            </div>
        );
    }
    else{
        return (
            <div className="card-deck">
                <div key="m1" className="movie-card"><center className="text-center-not">ไม่พบผลลัพธ์ใดๆ โปรดลองอีกครั้ง</center></div>
            </div>
        );
    }

};

const MovieList = (props) => (
    <div>
        {getMovies(props.movies,props.status)}
    </div>
);

MovieList.defaultProps = {
    movies: []
};

MovieList.propTypes = {
    movies: PropTypes.array
};

export default MovieList;