import React from 'react';
import { withStyles } from "@material-ui/core/styles";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Button from '@material-ui/core/Button';

import axios from "axios";
import { config } from "../configs/constant";

const MySwal = withReactContent(Swal)




const styles = theme => ({
  content:{
    maxWidth:'350px',
    backgroundColor: '#ffffff',
    padding:'5px',
    margin:' 10px auto',
    // borderRadius: '5px',
  },
  boxContent:{
    display: 'flex',
    position: 'relative',
  },
  boxImage:{
    width:'30%'
  },
  imageCarousel:{
    width:'100%'
  },
  boxdetail:{
    width:'70%',
    paddingLeft:'10px'
  },
  btnRedeem:{
    position: 'absolute',
    bottom: '0',
    right: '10px',
    width: '90px',
    backgroundColor: '#2876f9',
    backgroundImage: JSON.parse(localStorage.getItem("_profileSetting")).backgroundImage,
    textAlign: 'center',
    fontSize: '20px',
    borderRadius: '100px',
    color: '#ffffff'
  },
  textdetail:{
    fontSize:'18px'
  }
});


class ListItemContent extends React.Component {
  state = {
    ListItem:'',
    contentList:[]
  };



  componentDidMount(){

    this.GetPromotion()
  }


  GetPromotion = () => {
    const { classes } = this.props;
    document.body.classList.add("load-screen");
    axios({
        method: 'post',
        url: (`${config.url}/content/GetPromotion`),
        headers: {'Authorization': 'Bearer ' + window.localStorage.getItem("_token")},
    })
    .then(resp => {
      document.body.classList.remove("load-screen");
      if(resp.data.status){
        this.setState({
          contentList:resp.data.contentModelList,
          ListItem:resp.data.contentModelList.map(o=> {
            console.log(o)
            return(
              <div className={classes.content}>
              <div className={classes.boxContent}>
                <div className={classes.boxImage}>
                <img className={classes.imageCarousel} src={o.imageURL} />
                </div>
                <div className={classes.boxdetail}>
                  <h4>{o.title}</h4>
                  <div>{o.detail}</div>
                  <div id={o.contentId} onClick={this.RedeemAlert} className={classes.btnRedeem}>รับโปรโมชั่น</div>
                </div>
              </div>
            </div>
            )
          })
        })
      }
    })
    .catch((error) => {

    });
  }


  ContentLog = (e) => {
    const { classes } = this.props;
    axios({
        method: 'post',
        url: (`${config.url}/content/ContentLog`),
        headers: {'Authorization': 'Bearer ' + window.localStorage.getItem("_token")},
        data:{
          CotnentId:e
        }
    })
    .then(resp => {

    })
    .catch((error) => {

    });
  }



  RedeemAlert = (e) =>{
    const { classes } = this.props;
    var getItem = this.state.contentList.filter(o=> o.contentId == e.target.id)
    this.ContentLog(getItem[0].contentId)
    MySwal.fire({
      html:(
      <div className={classes.content}>
        <div className={classes.boxContent2}>
          <div className={classes.boxImage2}>
          <img className={classes.imageCarousel} src={getItem[0].imageURL} />
          </div>
          <div className={classes.boxdetail2}>
            <br/>
            <h4>{getItem[0].title}</h4>
            <h5 className={classes.textdetail}>{getItem[0].detail}</h5>
          </div>
          <Button variant="outlined" color="primary" onClick={this.closePopup}>
            เข้าใจแล้ว
          </Button>
        </div>
      </div>
      ),
      showConfirmButton: false,
    })
  }

  closePopup = (e) =>{
    MySwal.close()
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.state.ListItem}
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(ListItemContent);