import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core/styles";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import StarIcon from '@material-ui/icons/Star';
import ReactCardFlip from 'react-card-flip';
import QRCode from "react-qr-code";

import Navigation from "../component/Navigation";
import PopUpDetail from "../component/ListItemContent";
import HeadApp from '../component/HeadApp';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import FadeIn from "react-fade-in";
import Lottie from "react-lottie";
import LoadingData from "../Loading.json";
import SuccessData from "../Success.json";

import RedeemOutlinedIcon from '@material-ui/icons/RedeemOutlined';

import Bgcard from "../asset/images/bgcard.png";
import bgCard from "../asset/images/card-osk.jpg";
import call from "../asset/images/call.png";

import withReactContent from 'sweetalert2-react-content'

import OtpInput from 'react-otp-input';

import Share from '../component/Share'



import axios from "axios";
import { config } from "../configs/constant";
import Swal from 'sweetalert2'
const MySwal = withReactContent(Swal)

const styles = theme => ({
  main:{
    backgroundColor: '#000000',
  },
  appLogo:{
    width:'80%',
    position: 'absolute',
    top: '-100%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  appSetting:{
    width:'50px',
    height :'50px',
    margin: '10px',
    fontSize:'40px',
    textAlign:'center',
    borderRadius:'5px',
    border:'unset',
  },
  content:{
    backgroundColor: '#2876f9',
    backgroundImage: JSON.parse(localStorage.getItem("_profileSetting")).backgroundImage,
    borderRadius:'5px',
    color:'#ffffff',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  PageContent: {
    backgroundColor: '#000000',
    height:'100vh'
  },
  PageContentloading:{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  LogImage :{
    width:'100%'
  },
  button :{
    maxWidth: '560px',
    minHeight: '50px',
    textAlign: 'center',
    lineHeight: '50px',
    fontSize:'25px',
    borderRadius:'100px',
    margin:'50px auto',
    backgroundColor: '#ffffff',
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontFamily: 'DBHelvethaicaX-55Regular',
    fontSize:'20px'
  },
  point: {
    flexGrow: 1,
    fontFamily: 'DBHelvethaicaX-55Regular',
    fontSize:'20px',
    textAlign:'right'
  },
  boxCard:{
    // width: '75%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  card:{
    maxWidth:'330px',
    minHeight:'200px',
    borderRadius:'10px',
    border:"solid 1px #cccccc",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: '#2d3436',
    backgroundImage: 'linear-gradient(315deg, #2d3436 0%, #d3d3d3 74%)',
    backgroundImage: `url(${Bgcard})`

  },
  paddingCard:{
    padding: '5px'
  },
  app:{
    backgroundColor:'#28292d',
    position: 'fixed',
    backgroundColor: '#923cb5',
    backgroundImage: 'linear-gradient(147deg, #923cb5 0%, #000000 74%)',
    font:{
      fontSize:'20px',
    }
  },
  textlogo:{
    color:'#ffffff',
    textAlign:'left'
  },
  imageCarousel:{
    width:'100%'
  },
  LogoImage:{
    width:'100%'
  },
  tabpromotion:{
    color: '#ffffff',
    margin: '15px 0px auto',
    fontSize: '20px',
    backgroundColor: '#28292d',
    padding: '5px',
    borderRadius: '10px 10px 0 0',
  },
    titlePromotionicon:{
      display: 'inline-block',
    },
    titlePromotiontext:{
      display: 'inline-block',
      marginLeft:'5px'
    },
    tabpromotionL:{
      width:'50%',
      display: 'inline-block',
      verticalAlign: 'middle'
    },
    tabpromotionR:{
      width:'50%',
      textAlign:'right',
      display: 'inline-block',
      verticalAlign: 'middle'
    }
});



const LoadingOptions = {
  loop: true,
  autoplay: true,
  animationData: LoadingData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};
const SuccessOptions = {
  loop: false,
  autoplay: true,
  animationData: SuccessData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};


class Profile extends React.Component {
  state = {
    loading:false,
    isFlipped:true,
    ListPromotion:"",
    ListNews:"",
    userProfile:"",
    refURL:"",
    otp:""
  };


  componentDidMount(){
    document.body.classList.add("load-screen");
    this.setState({
      userProfile:JSON.parse(localStorage.getItem("_userProfile"))
    })
    this.GetPromotion();
    this.GetNews();
    // this.App();
  }


  App = (e) =>{
    const { classes } = this.props;
    MySwal.fire({
      html:(
      <div className={classes.content}>
        <img className={classes.appLogo} src={JSON.parse(localStorage.getItem("_profileSetting")).logo2}/>
        <div className={classes.boxContent2}>
          <br/>
          <br/>
      ร่วมสนุกกับเลขนำโชค 
        </div>
      </div>
      ),
      showConfirmButton: false,
    })
  }

  closePopup = (e) =>{
    MySwal.close()
  }


  RegisterNow = () =>{
    this.setState({loading:true})
    setTimeout(() => {
      this.setState({loading:true});
      this.props.history.push("/profile");
    }, 2000);
  }

  handleClick = (e) => {
    // e.preventDefault();
    // this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
  }


  GetPromotion = () => {
    const { classes } = this.props;
    axios({
        method: 'post',
        url: (`${config.url}/content/GetPromotion`),
        headers: {'Authorization': 'Bearer ' + window.localStorage.getItem("_token")},
    })
    .then(resp => {
      document.body.classList.remove("load-screen");
      if(resp.data.status){
        this.setState({
          ListPromotion:resp.data.contentModelList.map(o=> {
            console.log(o)
            return(
              <div><img className={classes.imageCarousel} src={o.imageURL} /></div>
            )
          })
        })
      }
    })
    .catch((error) => {

    });
  }


  GetNews = () => {
    document.body.classList.add("load-screen");
    const { classes } = this.props;
    axios({
        method: 'post',
        url: (`${config.url}/content/GetNews`),
        headers: {'Authorization': 'Bearer ' + window.localStorage.getItem("_token")},
    })
    .then(resp => {
      document.body.classList.remove("load-screen");
      if(resp.data.status){
        this.setState({
          ListNews:resp.data.contentModelList.map(o=> {
            console.log(o)
            return(
              <div><img className={classes.imageCarousel} src={o.imageURL} /></div>
            )
          })
        })
      }
    })
    .catch((error) => {

    });
  }

  render() {
    const { classes } = this.props;
    if(this.state.userProfile.userId != null || this.state.userProfile.userId != ""){
      var refURL = `${JSON.parse(localStorage.getItem("_profileSetting")).webUrl}register?member_ref=${this.state.userProfile.userId}`
      
    }
    return (
      <>
      {
        this.state.loading ? (
          <div id="fadein" className={classes.PageContent}>
            <div className={classes.PageContentloading}>
              <Lottie options={LoadingOptions} height={200} width={300}/>
            </div>
          </div>
        ):(
          <div id="fadein" className={classes.PageContent}>
          <HeadApp/>            
          <main className={classes.main}>
            <br/>
            <br/>
            <br/>
          <center className={classes.paddingCard}>
          <ReactCardFlip className={classes.boxCard} isFlipped={this.state.isFlipped} flipDirection="horizontal">

              <div className={classes.card} onClick={this.handleClick}>
                <div className={classes.boxCard}>
                  {/* <a href="https://lin.ee/3kqrgRw"><img src={call} alt="ศูนย์บริการ/แจ้งปัญหา" height="36" border="0"/></a> */}
                </div>
              </div>

              <div className={classes.card} onClick={this.handleClick}>
              <div className={classes.boxCard}>
                <h2 className={classes.textlogo}> MEMBER CARD VIP</h2>
                <h4 className={classes.textlogo}>
                  รหัสสมาชิก {this.state.userProfile.userId}
                </h4>
                <h4 className={classes.textlogo}>
                  คุณ {this.state.userProfile.name} {this.state.userProfile.lname}
                </h4>
                <Share url={refURL} title={refURL}/>
                {/* ---พลิกด้านหลัง--- */}
                </div>
              </div>

          </ReactCardFlip>
          </center>
          <>
          <div className={classes.tabpromotion}>
            <div className={classes.tabpromotionL}>
              <div className={classes.titlePromotionicon}><RedeemOutlinedIcon/></div>
              <div className={classes.titlePromotiontext}>โปรโมชั่นแนะนำ</div>
            </div>
            <div className={classes.tabpromotionR}>
              {/* <div className={classes.titlePromotiontext}>ดูทั้งหมด</div> */}
            </div>
          </div>
          <div className={classes.promotionCard}>
              <Carousel responsive={responsive}>
                {this.state.ListPromotion}
              </Carousel>
            </div>
          </>

          <>
          <div className={classes.tabpromotion}>
            <div className={classes.tabpromotionL}>
              <div className={classes.titlePromotionicon}><RedeemOutlinedIcon/></div>
              <div className={classes.titlePromotiontext}>ข่าวสารและกิจกรรม</div>
            </div>
            <div className={classes.tabpromotionR}>
              {/* <div className={classes.titlePromotiontext}>ดูทั้งหมด</div> */}
            </div>
          </div>
          <div className={classes.promotionCard}>
              <Carousel responsive={responsive}>
                {this.state.ListNews}
              </Carousel>
            </div>
          </>
          <br/>
          <br/>
          <br/>
          <Navigation/>
          </main>
          </div>
        )
      }
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Profile);