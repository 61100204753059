import React from 'react';
import {withRouter} from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookOpen } from '@fortawesome/free-solid-svg-icons'



const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontFamily: 'DBHelvethaicaX-55Regular',
    fontSize:'20px'
  },
  point: {
    flexGrow: 1,
    fontFamily: 'DBHelvethaicaX-55Regular',
    fontSize:'20px',
    textAlign:'right'
  },
  app:{
    backgroundColor: '#ffffff',
    // backgroundImage: JSON.parse(localStorage.getItem("_profileSetting")).backgroundImage,
    // maxWidth:'912px',
    font:{
      fontSize:'20px',
    }
  },
  profileImage:{
    maxWidth:'50px',
    height:'100%',
    backgroundColor:'#ffffff',
    display:'inline-block',
    marginRight:'5px',
    borderRadius: '50px',
    overflow: 'hidden',
    verticalAlign: 'middle'
  },
  boxImage:{
    width:'100%',

  }
});

class Header extends React.Component {
  state = {
    value:'Recents',
    userProfile:"",
  };

  componentDidMount(){
  // this.Verify();
  //   this.setState({
  //     userProfile:JSON.parse(window.localStorage.getItem("_userProfile"))
  //   })
  }


  handleChange = (e) => {
    this.setState({
      value:e
    })
  };

  Logout = (e) =>{
    localStorage.removeItem("_token");
    localStorage.removeItem("_userProfile")
    window.location.replace("storepage")
  }
  toBack = (e) =>{
    window.location.replace("member")
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
      <AppBar className={classes.app} position="">
        <Toolbar>
        <Typography variant="h6" className={classes.title}>
          <div>
          {/* <div className={classes.profileImage}>
            <img className={classes.boxImage} src={this.state.userProfile.imgProfile == "" ? config.imgDef:config.imgDef}/>
          </div> */}
          <samp className='title-text-h2'>{this.props.title}</samp>
          </div>
          </Typography>
          <Typography variant="h6" className={classes.title}>
            {/* OSAKA789 */}
          </Typography>
          {/* <Typography className={classes.point} color="inherit" onClick={this.Logout}>ออกจากระบบ</Typography> */}
          <Typography className={classes.point + " all-icon-cr"}  onClick={this.toBack}>ดูทั้งหมด <FontAwesomeIcon icon={faBookOpen}/></Typography> 
        </Toolbar>
      </AppBar>
      <iframe src={this.state.url} title="description"></iframe>
    </div>
    );
  }
}

Header.defaultProps = {
    title: 'Title'
};

Header.propTypes = {
    title: PropTypes.string
};

export default withStyles(styles, { withTheme: true })(withRouter(Header));