import React from 'react';
import { Container } from "./styles";
import { Row,Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Carousel from '../../component/Carousel'
import CatalogView from '../../component/CatalogView'




class MemberPage extends React.Component {
  state = {
    loading:"",
  };

  componentDidMount(){
  }

  render() {
    return (
        <>
        <Container>
            <div>
                <Carousel/>
                <CatalogView title="ได้รับความนิยมสูงสุด" status={false} max={6}/>
                <CatalogView title="ใหม่ล่าสุด" status={false} max={10}/>
                <CatalogView title="ทั้งหมด" status={false} max={22}/>
            </div>
            <br/>
            <br/>
        </Container>
        </>
    );
  }
}

export default (MemberPage);