import styled from "styled-components";
import imgbg from '../../asset/images/neeyai/cover.jpg'

export const Container = styled.div`
  background-color: #000000;
  /* background: rgba(256, 256, 256, 0.6); */
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  height: 100%;
  max-Width: 100%;
  color: #000000;
  z-index:900;
  margin:auto;
  .app-conten {
    width: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    img {
      width: 100%;
    }
    .fix-img {
      max-width: 300px;
    }
    .fix-loading{
      width: 7%;
    }
    div {
      align-items: center;
      justify-content: center;
    }
    .fix-center {
      text-align: center;
    }
    .app-login-group {
      font-size: 20px;
      border-radius: 50px;
      height: 50px;
      position: relative;
      &.btn-line {
        background-color: #00b300;
        color: #ffffff;
        font-size: 1.5rem;
        cursor: pointer;
      }
      &.btn-facebook {
        background-color: #4267b2;
        color: #ffffff;
        font-size: 1.5rem;
        cursor: pointer;
      }
      &.btn-apple {
        background-color: #000000;
        color: #ffffff;
        font-size: 1.5rem;
        cursor: pointer;
      }
      &.btn-login {
        background-color: #ef6184;
        color: #ffffff;
        font-size: 1.5rem;
        cursor: pointer;
      }
    }
    .fix-size-iconline{
        font-size: 25px;
    }
    .app-center-div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }
    input {
      width: 100%;
      height: 40px;
      margin: auto;
      border: solid 1px #cccccc;
      border-radius: 10px;
    }
    h4 {
      margin-top: 5px;
      font-size: 18px;
      color: #ffffff;
    }
    a{
      color: #ef6184;
    }
    li {
      font-size: 12px !important;
    }
  }
  .header-story{
    max-width: 789px;
    height: 220px;
    display: flex;
    /* background-color: #000000; */
    /* background-image: url(${imgbg}); */
    background-size: cover;
    background-position: center;
    margin:auto ;

    .box-image{
      height: 220px;
      img{
        height: 100%;
      }
    }
    .box-detail{
      width: 100%;
      color: #ffffff;
      margin: 5px 5px;
    }
  }
`;
