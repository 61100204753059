import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core/styles";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import StarIcon from '@material-ui/icons/Star';
import ReactCardFlip from 'react-card-flip';
import QRCode from "react-qr-code";

import Navigation from "../component/Navigation";
import PromotionList from "../component/ListItemContentGame";

import axios from "axios";
import { config } from "../configs/constant";

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import FadeIn from "react-fade-in";
import Lottie from "react-lottie";
import LoadingData from "../Loading.json";
import SuccessData from "../Success.json";

import RedeemOutlinedIcon from '@material-ui/icons/RedeemOutlined';
import HeadApp from '../component/HeadApp';
import Countdown from '../component/Countdown';




const styles = theme => ({
  main:{
    backgroundColor: '#000000',
  },
  appAPI:{
    backgroundColor: '#bbbbbb',
    color: '#ffffff',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex:'1',
    width: '100%',
    textAlign:'center'
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  PageContent: {
    backgroundColor: '#000000',
    height:'100vh'
  },
  PageContentloading:{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  LogImage :{
    width:'100%'
  },
  button :{
    maxWidth: '560px',
    minHeight: '50px',
    textAlign: 'center',
    lineHeight: '50px',
    fontSize:'25px',
    borderRadius:'100px',
    margin:'50px auto',
    backgroundColor: '#ffffff',
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontFamily: 'DBHelvethaicaX-55Regular',
    fontSize:'20px'
  },
  point: {
    flexGrow: 1,
    fontFamily: 'DBHelvethaicaX-55Regular',
    fontSize:'20px',
    textAlign:'right'
  },
  boxCard:{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  card:{
    maxWidth:'360px',
    minHeight:'200px',
    borderRadius:'10px',
    border:"solid 1px #cccccc",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: '#2d3436',
    backgroundImage: 'linear-gradient(315deg, #2d3436 0%, #d3d3d3 74%)'

  },
  app:{
    backgroundColor:'#28292d',
    position: 'fixed',
    backgroundColor: '#923cb5',
    backgroundImage: 'linear-gradient(147deg, #923cb5 0%, #000000 74%)',
    font:{
      fontSize:'20px',
    }
  },
  textlogo:{
    color:'#ffffff'
  },
  imageCarousel:{
    width:'100%'
  },
  tabpromotion:{
    color: '#ffffff',
    margin: '15px 0px auto',
    fontSize: '20px',
    backgroundColor: '#28292d',
    padding: '5px',
    borderRadius: '10px 10px 0 0',
  },
    titlePromotionicon:{
      display: 'inline-block',
    },
    titlePromotiontext:{
      display: 'inline-block',
      marginLeft:'5px'
    },
    tabpromotionL:{
      width:'50%',
      display: 'inline-block',
      verticalAlign: 'middle'
    },
    tabpromotionR:{
      width:'50%',
      textAlign:'right',
      display: 'inline-block',
      verticalAlign: 'middle'
    },
    btnRedeem:{
      bottom: '0',
      right: '10px',
      backgroundColor: '#343a40',
      textAlign: 'center',
      fontSize: '20px',
      borderRadius: '100px',
      color: '#ffffff',
      backgroundColor: '#2876f9',
      backgroundImage: 'linear-gradient(315deg, #2876f9 0%, #6d17cb 74%)',
    },
});



const LoadingOptions = {
  loop: true,
  autoplay: true,
  animationData: LoadingData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};
const SuccessOptions = {
  loop: false,
  autoplay: true,
  animationData: SuccessData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};


class Privilege extends React.Component {
  state = {
    loading:false,
    isFlipped:true,
    tomorrow:"",
    verify:false,
    regamePage:true,
    
  };

  componentDidMount(){
    let _mapTime = "";
    var today = new Date();
    var tomorrow = new Date();
    tomorrow.setDate(today.getDate()+1);

    _mapTime = `${tomorrow.getFullYear()}-${tomorrow.getMonth() < 10 ? '0'+(tomorrow.getMonth()+1):tomorrow.getMonth()+1}-${tomorrow.getDate() < 10 ? '0'+(tomorrow.getDate()+1):tomorrow.getDate()+1}T00:00:00`
    this.setState({tomorrow:_mapTime})

  }


  RegisterNow = () =>{
    this.setState({loading:true})
    setTimeout(() => {
      this.setState({loading:true});
      this.props.history.push("/profile");
    }, 2000);
  }

  handleClick = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
  }

  reGame = () =>{
    window.location.reload();
    window.localStorage.setItem("_repage",false)
  }


  render() {
    const { classes } = this.props;

    return (
      <>
      {
        this.state.loading ? (
          <div id="fadein" className={classes.PageContent}>
            <div className={classes.PageContentloading}>
              <Lottie options={LoadingOptions} height={200} width={300}/>
            </div>
          </div>
        ):(
          <div id="fadein" className={classes.PageContent}>
            <HeadApp/>
          <main className={classes.main}>
            <br/>
            <br/>
            <br/>
              <PromotionList /> 
            <br/>
            <br/>
            <br/>
            <Navigation/>
          </main>
          </div>
        )
      }
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Privilege);