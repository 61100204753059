import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { ProgressBar, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import zIndex from '@material-ui/core/styles/zIndex';

import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';

const MySwal = withReactContent(Swal)




const styles = theme => ({
  content:{
    maxWidth:'350px',
    backgroundColor: '#ffffff',
    padding:'5px',
    margin:' 10px auto',
    // borderRadius: '5px',
    minHeight:'100px',
  },
  boxContent:{
    display: 'flex',
    position: 'relative',
  },
  boxImage:{
    width:'30%'
  },
  imageCarousel:{
    width:'100%',
    borderRadius:'5px'
  },
  boxdetail:{
    width:'70%',
    paddingLeft:'10px'
  },
  btnRedeem:{
    // position: 'absolute',
    bottom: '0',
    right: '10px',
    // width: '90px',
    backgroundColor: '#343a40',
    textAlign: 'center',
    fontSize: '20px',
    borderRadius: '100px',
    color: '#ffffff',
    backgroundColor: '#2876f9',
    backgroundImage: JSON.parse(localStorage.getItem("_profileSetting")).backgroundImage,
    margin:'10px 10px 10px 10px',
  },
  textdetail:{
    fontSize:'18px'
  },
  tabType:{
    justifyContent:'space-evenly',
    position: '-webkit-sticky',
    position: 'sticky',
    top: '56px',
    zIndex:'1'
  }
});

const List = []

class ListItemContentGame extends React.Component {
  state = {
    ListItem:'',
    progress:50,
    indexgame:4,
    category:[]
  };



  componentDidMount(){
    this.GetGameList()
  }

  LoginGame = (e) =>{
    const vendor = e.target.getAttribute('vendor')
    const gameId = e.target.getAttribute('gameId')
    const gameCode = e.target.getAttribute('gameCode')
    const productcode =  e.target.getAttribute('productcode')

    window.location.href = `https://g2gbet.com/game/?provider=${productcode}&GameID=${gameCode}`;

    // window.location.href = `https://g2gbet.com/live-casino/game/?provider=pretty`;
  }

  GetGameList (){
    const { classes } = this.props;
    document.body.classList.add("load-screen");
    axios.get('https://g2gbet-client-d.askmebet.cloud/game/getProductList')
    .then(resp=>{
    document.body.classList.remove("load-screen");
      resp.data.data.map((o,index)=>{
        const _mapData = {
          label:o.productName,
          value:index
        }
        var setdata = this.state.category.concat(_mapData);
        this.setState({ category: setdata })
      })

      
      this.setState({
        ListItem:resp.data.data[this.state.indexgame].lists.map(o=> {
          var CountNow = (45 + Math.floor(Math.random() * 45))
          return(
            <div key={o.gameId} className={classes.content}>
            <div className={classes.boxContent}>
              <div className={classes.boxImage}>
              <img className={classes.imageCarousel} vendor="ambgame2" src={"https://cdn.ambbet.com/"+o.imgUrl}/>
              </div>
              <div className={classes.boxdetail}>
                <h4>{o.gameName}</h4>
                <div>
                  <ProgressBar>
                    <ProgressBar now={CountNow}   variant="success" label={`WIN ${CountNow}%`} key={1} />
                    <ProgressBar now={100-CountNow}   variant="danger" label={`LOST ${100-CountNow}%`} key={2} />
                  </ProgressBar>
                </div>
                <div key={o.gameId} gameCode={o.gameCode} gameId={o.gameId} gameName={o.gameName} gameType={o.gameType} productCode={o.productCode} onClick={this.LoginGame} className={classes.btnRedeem}>เล่นเกมส์</div>
              </div>
            </div>
          </div>
          )
        })
      })

    })
    .catch(error=>{
      console.log(error)
    })
  }

  Getgame = (e) =>{
    this.setState({
      indexgame:e.value
    })
    this.GetGameList()
  }

  RedeemAlert = (e) =>{
    const { classes } = this.props;
    var getItem = List.filter(o=> o.itemId == e.target.id)
  
    MySwal.fire({
      html:(
      <div className={classes.content}>
        <div className={classes.boxContent}>
          <div className={classes.boxImage}>
          <img className={classes.imageCarousel} src={getItem[0].imageUrl} />
          </div>
          <div className={classes.boxdetail}>
            <h4>{getItem[0].title}</h4>
            <h5 className={classes.textdetail}>{getItem[0].detail}</h5>
          </div>
        </div>
      </div>
      ),
      showConfirmButton: false,
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.tabType}>

            <Select options={ this.state.category } placeholder="เลือกค่ายเกมส์" onChange={this.Getgame}/>


        </div>
        {this.state.ListItem}
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(ListItemContentGame);