import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core/styles";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import StarIcon from '@material-ui/icons/Star';
import ReactCardFlip from 'react-card-flip';
import QRCode from "react-qr-code";

import Navigation from "../component/Navigation";
import PromotionList from "../component/ListItemContent";

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import FadeIn from "react-fade-in";
import Lottie from "react-lottie";
import LoadingData from "../Loading.json";
import SuccessData from "../Success.json";

import RedeemOutlinedIcon from '@material-ui/icons/RedeemOutlined';
import HeadApp from '../component/HeadApp';

import axios from "axios";
import { config } from "../configs/constant";


const styles = theme => ({
  main:{
    backgroundColor: '#000000',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  PageContent: {
    backgroundColor: '#000000',
    height:'100vh'
  },
  PageContentloading:{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  LogImage :{
    width:'100%'
  },
  button :{
    maxWidth: '560px',
    minHeight: '50px',
    textAlign: 'center',
    lineHeight: '50px',
    fontSize:'25px',
    borderRadius:'100px',
    margin:'50px auto',
    backgroundColor: '#ffffff',
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontFamily: 'DBHelvethaicaX-55Regular',
    fontSize:'20px'
  },
  point: {
    flexGrow: 1,
    fontFamily: 'DBHelvethaicaX-55Regular',
    fontSize:'20px',
    textAlign:'right'
  },
  boxCard:{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  card:{
    maxWidth:'360px',
    minHeight:'200px',
    borderRadius:'10px',
    border:"solid 1px #cccccc",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: '#2d3436',
    backgroundImage: 'linear-gradient(315deg, #2d3436 0%, #d3d3d3 74%)'

  },
  app:{
    backgroundColor:'#28292d',
    position: 'fixed',
    font:{
      fontSize:'20px',
    }
  },
  textlogo:{
    color:'#ffffff'
  },
  imageCarousel:{
    width:'100%'
  },
  tabpromotion:{
    color: '#ffffff',
    margin: '15px 0px auto',
    fontSize: '20px',
    backgroundColor: '#28292d',
    padding: '5px',
    borderRadius: '10px 10px 0 0',
  },
    titlePromotionicon:{
      display: 'inline-block',
    },
    titlePromotiontext:{
      display: 'inline-block',
      marginLeft:'5px'
    },
    tabpromotionL:{
      width:'50%',
      display: 'inline-block',
      verticalAlign: 'middle'
    },
    tabpromotionR:{
      width:'50%',
      textAlign:'right',
      display: 'inline-block',
      verticalAlign: 'middle'
    },
    eventImg:{
      width: '100%',
    },
  eventUse:{
      width: '100%',
      margin: '2px 0px',
      position: 'relative',
  },
  eventUseText:{
      fontSize: '40px',
      color:'#424242',
      top: '50%',
      left: '50%',
      width: '100%',
      textAlign: 'center',
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
  }
    
});



const LoadingOptions = {
  loop: true,
  autoplay: true,
  animationData: LoadingData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};
const SuccessOptions = {
  loop: false,
  autoplay: true,
  animationData: SuccessData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};


class Event extends React.Component {
  state = {
    loading:false,
    isFlipped:true,
    listEvent:"",
  };


  componentDidMount(){
    document.body.classList.add("load-screen");
    this.GetEvent();
  }


  GetEvent = () => {
    const { classes } = this.props;
    axios({
        method: 'post',
        url: (`${config.url}/content/appSetting`),
        headers: {'Authorization': 'Bearer ' + window.localStorage.getItem("_token")},
    })
    .then(resp => {
      document.body.classList.remove("load-screen");
    if(resp.data.status){
        if(resp.data.app.length > 0){
           this.setState({
          listEvent:resp.data.app.map(o=> {
            return(
              <>
                <br/>
                <div className={classes.eventUse}>
                  <img key={o.appSettingId} value={o.appSettingId}  onClick={o.status == true ? ()=>this.props.history.push(`/EventApp?app=${o.appSettingId}&appref=${o.name}`) : ""} className={classes.eventImg} src={o.imageUrl}/>
                </div>
                <br/>
              </>
            )
          })
        })
        }
        else{
          this.setState({
            listEvent:<div className={classes.eventUseText}><h2>ไม่มีกิจกรรมในขณะนี้</h2></div>
          })
        }
      }
    })
    .catch((error) => {

    });
  }



  RegisterNow = () =>{
    this.setState({loading:true})
    setTimeout(() => {
      this.setState({loading:true});
      this.props.history.push("/profile");
    }, 2000);
  }

  handleClick = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
  }

  render() {
    const { classes } = this.props;
    return (
      <>
      {
        this.state.loading ? (
          <div id="fadein" className={classes.PageContent}>
            <div className={classes.PageContentloading}>
              <Lottie options={LoadingOptions} height={200} width={300}/>
            </div>
          </div>
        ):(
          <div id="fadein" className={classes.PageContent}>
            <HeadApp/>            
          <main className={classes.main}>
            <br/>
            <br/>
              {this.state.listEvent}
            <br/>
            <br/>
            <Navigation/>
          </main>
          </div>
        )
      }
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Event);