import React from 'react';
import PropTypes from 'prop-types';
import StarRating from '../StarRating';

const MovieCard = (props) => (
    <>
    <a href={`/read?id=${props.movie.constantId}`}>
        <div className="movie-card">
        <div className={props.status == true ? 'movie-card card2':'movie-card card'}>
            <img className="card-img-top cover-size-img" src={props.movie.imageUrl} alt="" />
            <div className="card-body">
                {props.status == true ? '':<h4 className="card-title">{props.movie.title}</h4>}
                {props.status == true ? '':<h6 className="card-subtitle mb-2 text-muted">โดย : {props.movie.author}</h6>}
                {/* {props.status == true ? '':<p className="text-justify" style={{fontSize: '14px'}}>{props.movie.review}</p>} */}
                
                {/* {props.status == true ? <div className="dtw"><div className="app-center-div" >ดาวน์โหลด</div></div>:<a href={`/read?id=${props.movie.constantId}`}><div className="dtw"><div className="app-center-div" >อ่าน</div></div></a>} */}
                {props.status == true ? <div className="dtw"><div className="app-center-div" >ดาวน์โหลด</div></div>:""}
            </div>
            <StarRating rating={props.movie.rating} view={props.movie.totalView} epcount={props.movie.epcount} />
            {/* <div className="card-footer">
                <div className="clearfix">
                    <div className="float-left mt-1">
                        <StarRating rating={props.movie.rating} />
                    </div>
                    <div className="card-footer-badge float-right badge badge-primary badge-pill">{props.movie.rating}</div>
                </div>
            </div> */}
        </div>
    </div>
    </a>
    </>

);

MovieCard.defaultProps = {
    movie: {}
};

MovieCard.propTypes = {
    movie: PropTypes.object
};

export default MovieCard;