import React from 'react';
import { Container } from "./styles";
import { Row,Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'

import jwt_decode from "jwt-decode";

import loading from '../../asset/images/neeyai/loading.gif';
import anime from '../../asset/images/neeyai/RAW-Login_v3.png';
import vdo from '../../asset/vdo/vdobg1.mp4';

import axios from "axios";
import { config } from "../../configs/constant";


class RegisterPage extends React.Component {
  state = {
    loading:false,
    imageProfile:"",
    imageProfileDef : "https://i.pinimg.com/originals/0a/f3/c9/0af3c9613761d2d2394d99312aeba397.gif",
    loading:"",
    lookinput:false,
    register:false,
    password:"",
    cfpassword:"",
    displayname:"",
    email:"",
    validated:false,
    logText:"",
  };

  componentDidMount(){
    var url = new URL(window.location.href);
    var token = url.searchParams.get("token");

    if(token != null){
      console.log("-----------------------------------------------toekn")
      var decoded = jwt_decode(token);
      console.log(decoded)
      this.setState({
        imageProfile:decoded.linePicture,
        displayname:decoded.lineName
      })
    }
  }


CallRegister = () =>{
  var url = new URL(window.location.href);
  var token = url.searchParams.get("token");

  if(token != null){
    if(this.state.password == this.state.cfpassword && this.state.password != "" && this.state.cfpassword != "" && this.state.displayname != "" && this.state.email != ""){
      this.setState({
        loading:loading,
        lookinput:true,
        logText:"",
      })
      this.Lineregister(token)
    }
  }
  else{
    if(this.state.password == this.state.cfpassword && this.state.password != "" && this.state.cfpassword != "" && this.state.displayname != "" && this.state.email != ""){
      this.setState({
        loading:loading,
        lookinput:true,
        logText:"",
      })
      this.register()
    }
  }
}


register = () =>{
  var querystring = require('querystring');
  axios.post(`${config.url}/neeyai/Authentication/Line/Register`,
  querystring.stringify(
    {
      email:this.state.email,
      password:this.state.password,
      displayname:this.state.displayname,
      storeId:1
    }
  ))
  .then(resp => {
    if(resp.data.status){
      window.sessionStorage.setItem("token",resp.data.token)
      setTimeout(() => {
        this.setState({loading:true});
        //this.props.history.push("/member?token="+resp.data.token);
        window.location.replace("/loginpage?token="+resp.data.token);
      }, 2000);
    }
    else{
      this.setState({
        loading:false,
        lookinput:false,
        logText:resp.data.text
      });
    }
  })
  .catch((error) => {

  });
}

Lineregister = (token) =>{

  axios({
    method: 'post',
    url: (`${config.url}/neeyai/Authentication/Line/LineRegister`),
    headers: {'Authorization': 'Bearer ' + token},
    data:{
      displayname:this.state.displayname,
      email:this.state.email,
      password:this.state.password,
    }
  })
  .then(resp => {
    if(resp.data.status){
      window.sessionStorage.setItem("token",resp.data.token)
      setTimeout(() => {
        this.setState({loading:true});
        //this.props.history.push("/member?token="+resp.data.token);
        window.location.replace("/loginpage?token="+resp.data.token);
      }, 2000);
    }
    else{
      this.setState({
        loading:false,
        lookinput:false,
        logText:resp.data.text
      });
    }
  })
  .catch((error) => {

  });
}





inputRegister = (e) =>{
  this.setState({ [e.target.name]: e.target.value });
}


handleSubmit = (event) => {
  const form = event.currentTarget;
  if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
  }

  this.setState({validated:true})
};

  render() {
    return (
        <>
        <video autoPlay={true} muted loop id="myVideo">
            <source src={vdo} type="video/mp4"/>
        </video>
        <Container>
            <div className="app-conten">
                <Row className="fix-center">
                    {/* <Col sm={4}><img className="fix-img" src={anime}/></Col> */}
                    <Col sm={10}>
                        <Row>
                            <Col sm={12}><h1>สมัครสมาชิก Nee Yai</h1></Col>
                        </Row>
                        <hr/>
                        <Row>
                            <Col sm={3} className="cover-profile">
                              <div className="image-profile">
                                <img src={this.state.imageProfile != "" ? this.state.imageProfile : this.state.imageProfileDef}/>
                              </div>
                              {/* <Col sm={12}><h5>อัพโหลด/แก้ไขรูปภาพ</h5></Col> */}
                            </Col>
                            <Col sm={7}>
                              <Form className="fix-text-left" noValidate>
                              <Form.Group className="mb-3" controlId="formGridAddress1">
                                  <Form.Label>Display Name</Form.Label>
                                  <Form.Control value={this.state.displayname} placeholder="Display Name / นามปากกา" name="displayname" onChange={this.inputRegister} disabled={this.state.lookinput} required={true}/>
                                  <Form.Control.Feedback type="invalid">Please choose a username.</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formGridAddress1">
                                  <Form.Label>Email</Form.Label>
                                  <Form.Control placeholder="Email/อีเมล" name="email" onChange={this.inputRegister} value={this.state.email} disabled={this.state.lookinput}/>
                                </Form.Group>

                                <Row className="mb-3">
                                  <Form.Group as={Col} controlId="formGridPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" placeholder="Password/รหัสผ่าน" name="password" onChange={this.inputRegister} value={this.state.password} disabled={this.state.lookinput}/>
                                  </Form.Group>

                                  <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Label>Confirm password</Form.Label>
                                    <Form.Control type="password" placeholder="Confirm password/ยืนยันรหัสผ่าน" name="cfpassword" onChange={this.inputRegister} value={this.state.cfpassword} disabled={this.state.lookinput}/>
                                  </Form.Group>
                                </Row>
                                <Row>
                                    <Col sm={12}><h5>นโยบายคุ้มครองข้อมูลส่วนบุคคล <a href="#">ข้อกำหนดและเงื่อนไงการให้บริการ</a></h5></Col>
                                </Row>
                                <Row>
                                  <Col sm={12}>
                                    <div className="app-login-group btn-login fix-center"><div className="app-center-div" onClick={this.CallRegister}>ยินยอมและสมัครสมาชิก <img className="fix-loading" src={this.state.loading}/></div></div>
                                    <center><div className='logText'>{this.state.logText}</div></center>
                                  </Col>
                                </Row>
                              </Form>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Container>
        </>
    );
  }
}

export default (RegisterPage);