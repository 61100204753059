import React, { Component } from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import AppToken from '../CatalogView/components/AppToken';
import banner1 from '../../asset/images/neeyai/banner/banner1.jpg';
import banner2 from '../../asset/images/neeyai/banner/banner2.jpg';
import banner3 from '../../asset/images/neeyai/banner/banner3.jpg';

class carousel extends Component {



  render() {

    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };

      return (
        <div>
          <br/>
          <br/>
          <br/>
        <Carousel responsive={responsive}>
          <div><img className="img-c-w100" src={banner1}/></div>
          <div><img className="img-c-w100" src={banner2}/></div>
          <div><img className="img-c-w100" src={banner3}/></div>
        </Carousel>
   

        <AppToken title={this.props.title} />

        <hr/>
        </div>
      );
    }
  }

export default carousel;

