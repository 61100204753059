import React from 'react';
import { Container } from "./styles";
import { Row,Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'

import loading from '../../asset/images/neeyai/loading.gif';

import vdo from '../../asset/vdo/ShikiOriori.mp4';


class Landingpage extends React.Component {
  state = {
    loading:"",
  };

  componentDidMount(){
  }

Login = () =>{
    this.setState({
        loading:loading
    })
    window.location.href = 'https://dev-goo-api.azurewebsites.net/neeyai/Authentication/Line/CreateState?redirect=NeeYai';
}

login = () =>{
    this.props.history.push("/loginpage");
}


  render() {
    return (
        <>
        <video autoPlay={true} muted loop id="myVideo">
            <source src={vdo} type="video/mp4"/>
        </video>
        <Container>
            <div className="app-conten">
                <Row className="fix-center">
                    {/* <Col sm={4}><img className="fix-img" src={anime}/></Col> */}
                    <Col sm={8}>
                        <Row>
                            <Col sm={12}><h1>เข้าสู่ระบบ</h1></Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col sm={6}>
                            <div className="app-login-group btn-line"><div className="app-center-div" onClick={this.Login}>เข้าสู่ระบบด้วย LINE <img className="fix-loading" src={this.state.loading}/></div></div>
                            </Col>
                        </Row>
                        {/* <br/>
                        <Row>
                            <Col sm={6}>
                            <div className="app-login-group btn-facebook"><div className="app-center-div">เข้าสู่ระบบด้วย Facebook</div></div>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col sm={6}>
                            <div className="app-login-group btn-apple"><div className="app-center-div">เข้าสู่ระบบด้วย Apple</div></div>
                            </Col>
                        </Row> */}
                        <hr/>
                        <Row>
                            <Col sm={12}><h3>หรือ เข้าสู่ระบบด้วย บัญชี Nee Yai</h3></Col>
                        </Row>
                        {/* <Form>
                            <Form.Group className="mb-3" controlId="formGroupEmail">
                                <Form.Control type="email" placeholder="Username or Email" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formGroupPassword">
                                <Form.Control type="password" placeholder="Password" />
                            </Form.Group>
                        </Form> */}
                        <Row>
                            <Col sm={6}>
                            <div className="app-login-group btn-login"><div className="app-center-div" onClick={this.login}>เข้าสู่ระบบด้วย บัญชี Nee Yai</div></div>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col sm={12}><h4>หากยังไม่เป็นสมาชิก โปรด <a href="/register">สมัครสมาชิก</a></h4></Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Container>
        </>
    );
  }
}

export default (Landingpage);