import styled from "styled-components";

export const Container = styled.div`
  background: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  color: #000000;
    .app-conten{
      width: 90%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding-top: 40%;
      padding-bottom: 20%;
      img{
        width: 100%;
      }
      div{
        align-items: center;
        justify-content: center;
      }
      input{
        width: 50px !important;
        height: 50px;
        margin: 0 5px;
        border: solid 1px #cccccc;
        border-radius: 10px;
      }
      h4{
        margin-top: 5px;
        font-size: 18px;
        color: #7f7f7f;
      }
      li{
        font-size: 12px !important;
      }
    }
`;