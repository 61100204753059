import React from 'react';
import { Container } from "./styles";
import { Row,Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'

import jwt_decode from "jwt-decode";

import loading from '../../asset/images/neeyai/loading.gif';
import anime from '../../asset/images/neeyai/RAW-Login_v3.png';
import vdo from '../../asset/vdo/vdobg1.mp4';

import axios from "axios";
import { config } from "../../configs/constant";


class Login extends React.Component {
  state = {
    loading:false,
    imageProfile:"",
    imageProfileDef : "https://i.pinimg.com/originals/0a/f3/c9/0af3c9613761d2d2394d99312aeba397.gif",
    loading:"",
    lookinput:false,
    register:false,
    password:"",
    cfpassword:"",
    displayname:"",
    email:"",
    validated:false
  };

  componentDidMount(){
    var url = new URL(window.location.href);
    var token = url.searchParams.get("token");

    if(token != null){
      console.log("-----------------------------------------------toekn")
      var decoded = jwt_decode(token);
      console.log(decoded)
      this.setState({
        imageProfile:decoded.linePicture,
        displayname:decoded.lineName
      })

      this.setState({
        loading:true,
        loading:loading,
        lookinput:true,
        logText:""
      })

      window.sessionStorage.setItem("token",token)
      setTimeout(() => {
        this.setState({loading:true});
        //this.props.history.push("/member?token="+resp.data.token);
        window.location.replace("/member?token="+token);
      }, 1000);
    }
  }

CallRegister = () =>{


}

inputRegister = (e) =>{
  this.setState({ [e.target.name]: e.target.value });
}


handleSubmit = (event) => {
  const form = event.currentTarget;
  if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
  }

  this.setState({validated:true})
};

Login = () =>{
  if(this.state.email != "" && this.state.password != ""){
    this.setState({
      loading:true,
      loading:loading,
      lookinput:true,
      logText:""
    })
    var querystring = require('querystring');
    axios.post(`${config.url}/neeyai/Authentication/Line/login`,
    querystring.stringify(
      {
        email:this.state.email,
        password:this.state.password,
        storeId:1
      }
    ))
    .then(resp => {
      if(resp.data.status){
        window.sessionStorage.setItem("token",resp.data.token)
        setTimeout(() => {
          this.setState({loading:true});
          //this.props.history.push("/member?token="+resp.data.token);
          window.location.replace("/member?token="+resp.data.token);
        }, 2000);
      }
      else{
        this.setState({
          loading:false,
          lookinput:false,
          logText:resp.data.text
        });
      }
    })
    .catch((error) => {
  
    });
  }
}

  render() {
    return (
        <>
        <video autoPlay={true} muted loop id="myVideo">
            <source src={vdo} type="video/mp4"/>
        </video>
        <Container>
            <div className="app-conten">
                <Row className="fix-center">
                    {/* <Col sm={4}><img className="fix-img" src={anime}/></Col> */}
                    <Col sm={10}>
                        <Row>
                            <Col sm={12}><h1>เข้าสู่หน้าสมาชิก Nee Yai</h1></Col>
                        </Row>
                        <hr/>
                        <Row>
                            <Col sm={10}>
                              <Form className="fix-text-left" noValidate>

                                <Form.Group className="mb-3" controlId="formGridAddress1">
                                  <Form.Label>Email</Form.Label>
                                  <Form.Control placeholder="Email/อีเมล" name="email" onChange={this.inputRegister} value={this.state.email} disabled={this.state.lookinput}/>
                                </Form.Group>

                                <Row className="mb-3">
                                  <Form.Group  controlId="formGridPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" placeholder="Password/รหัสผ่าน" name="password" onChange={this.inputRegister} value={this.state.password} disabled={this.state.lookinput}/>
                                  </Form.Group>
                                </Row>

                                <Row>
                                  <Col sm={12}>
                                    <div className="app-login-group btn-login fix-center"><div className="app-center-div" onClick={this.Login}>เข้าสู่หน้าสมาชิก <img className="fix-loading" src={this.state.loading}/></div></div>
                                    <center><div className='logText'>{this.state.logText}</div></center>
                                  </Col>
                                </Row>
                              </Form>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Container>
        </>
    );
  }
}

export default (Login);