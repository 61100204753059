import React from 'react';
import { Route , withRouter} from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import EmojiEmotionsOutlinedIcon from '@material-ui/icons/EmojiEmotionsOutlined';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import HistoryOutlinedIcon from '@material-ui/icons/HistoryOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import { Sunglasses } from 'react-bootstrap-icons';

import { slide as Menu } from 'react-burger-menu'

import { config } from "../configs/constant";
import axios from "axios";
import jwt_decode from "jwt-decode";



const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontFamily: 'DBHelvethaicaX-55Regular',
    fontSize:'20px'
  },
  point: {
    flexGrow: 1,
    fontFamily: 'DBHelvethaicaX-55Regular',
    fontSize:'20px',
    textAlign:'right'
  },
  app:{
    backgroundColor:'#28292d',
    position: 'fixed',
    backgroundColor: '#ffffff',
    color:'#000000',
    // backgroundImage: JSON.parse(localStorage.getItem("_profileSetting")).backgroundImage,
    // maxWidth:'912px',
    font:{
      fontSize:'20px',
    }
  },
  profileImage:{
    maxWidth:'50px',
    height:'100%',
    backgroundColor:'#ffffff',
    display:'inline-block',
    marginRight:'5px',
    borderRadius: '50px',
    overflow: 'hidden',
    verticalAlign: 'middle'
  },
  boxImage:{
    width:'100%',

  }
});

class HeadApp extends React.Component {
  state = {
    value:'Recents',
    userProfile:"",
    OpenMenu:false,
    categoryList:"",
    token:null,
    displayname:"",
  };


  componentDidMount(){
    this.setState({
      token:window.sessionStorage.getItem("token")
    })
    if(window.sessionStorage.getItem("token") != null){

      var decoded = jwt_decode(window.sessionStorage.getItem("token"));
      this.setState({
        imageProfile:decoded.linePicture,
        displayname:decoded.lineName
      })
    }



  axios.get(config.url+'/neeyai/content/GetCategory')
  .then(resp=>{
    if(resp.data.status == true){
      this.setState({
        categoryList:resp.data.listCategory.map(o=>{
          return(
            <a id={o.categoryId} className="menu-item" href={`/content?categoryid=${o.categoryId}&name=${o.nameTh}`}>{o.nameTh}</a>
          )
        })
      })
    }
  })
  .catch(error=>{
  console.log(error)
  })
  }


  handleChange = (e) => {
    this.setState({
      value:e
    })
  };

  showSettingsMenu  = () =>{
    this.setState({OpenMenu: !this.state.OpenMenu})
  }

  Logout = (e) =>{
    localStorage.removeItem("_token");
    localStorage.removeItem("_userProfile")
    window.location.replace("storepage")
  }
  toBack = (e) =>{
    window.location.replace("myregister")
  }

  Verify = () => {
    const { classes } = this.props;
    axios({
        method: 'post',
        url: (`${config.url}/Authenticator/verify`),
        headers: {'Authorization': 'Bearer ' + window.localStorage.getItem("_token")},
    })
    .then(resp => {
      if(resp.data.status){
        this.setState({
          verify:resp.data.status,
          isMember:resp.data.statusMember,
          url:resp.data.url
        })
      }
    })
    .catch((error) => {
      setTimeout(() => {
        this.Verify()
      }, 30000);
    });
  }


  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
          <Menu isOpen={this.state.OpenMenu}>
          <a id="" className="menu-item" href={`/member`}>ดูทั้งหมด</a>
            {this.state.categoryList}
            <hr/>
            <samp className='title-text-h2'>เครื่องมือผู้เขียน</samp>
            <a id={0} className="menu-item" href={`/content?categoryid=${0}&name=${0}`}>เขียนนิยายเรื่องใหม่</a>
            <a id={0} className="menu-item" href={`/content?categoryid=${0}&name=${0}`}>เขียนนิยายต่อ</a>
            <a id={0} className="menu-item" href={`/content?categoryid=${0}&name=${0}`}>สถานะนิยาย</a>
            <a id={0} className="menu-item" href={`/content?categoryid=${0}&name=${0}`}>ถังขยะ</a>
          </Menu>
      <AppBar className={classes.app} position="static">
        <Toolbar>
        <Typography variant="h6" className={classes.title}>
          <div>
          {/* <div className={classes.profileImage}>
            <img className={classes.boxImage} src={this.state.userProfile.imgProfile == "" ? config.imgDef:config.imgDef}/>
          </div> */}
          {/* <samp onClick={this.showSettingsMenu}>หมวดหมู่</samp><samp onClick={this.showSettingsMenu}>/เติมเหรียญ</samp> */}
          <samp className='title-text-h2' onClick={this.showSettingsMenu}>เมนู</samp>
          </div>
          </Typography>
          <Typography variant="h6" className={classes.title}>
            {/* OSAKA789 */}
          </Typography>
          {/* <Typography className={classes.point} color="inherit" onClick={this.Logout}>ออกจากระบบ</Typography> */}
          <Typography className={classes.point} color="inherit" onClick={this.toBack}>{this.state.displayname == "" ? <samp className='title-text-h2' >เข้าสู่ระบบ/สมัครสมาชิก</samp>:'ยินดีต้อนรับ '+this.state.displayname}</Typography>
        </Toolbar>
      </AppBar>
      <iframe src={this.state.url} title="description"></iframe>
    </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withRouter(HeadApp));