import React, { Component } from 'react';
import MovieList from './MovieList';
import MovieService from '../../services/MovieService';
import { config } from "../../../../configs/constant";
import axios from 'axios';

export default class Movies extends Component {

    constructor() {
        super();

        this.state = {
            movies: []
        };
    }

    componentDidMount() {

        var url_string = window.location.href
        var url = new URL(url_string);
        var categoryId = url.searchParams.get("categoryid")
        if(categoryId == null){
            categoryId = 0;
        }

        if(this.props.status == true){
            this.setState(() => ({ movies: MovieService.getWallpaper() }));
        }
        else{
            axios.get(config.url+'/neeyai/content/GetContent?id='+categoryId+'&limit='+this.props.max)
            .then(resp=>{
                // if(this.props.max > 0){
                //     this.setState(() => ({ movies: resp.data.docResult.slice(0, this.props.max) }));
                // }
                // else{
                //     this.setState(() => ({ movies: resp.data.docResult }));
                // }
                this.setState(() => ({ movies: resp.data.docResult }));
            })
            .catch(error=>{
            console.log(error)
            })
        }
    }



    render() {
        return (
            <div className="container-fluid" style={{marginLeft: '-15px'}}>
                <div className="d-flex flex-row">                 
                    <div className="col-sm-12">
                        <MovieList movies={this.state.movies} status={this.props.status}/>
                    </div>
                </div>
            </div>
        );
    }
}